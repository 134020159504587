<template>
  <b-row class="match-height">
    <b-col cols="12">
      <!-- <criteria @my-event="test" /> -->
    </b-col>
    <b-col cols="12">
      <user-table ref="gtb" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import UserTable from './UserTable.vue'

export default {
  components: {
    BRow,
    BCol,
    // Criteria,
    UserTable,
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
