<template>
  <b-card-code>
    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <!-- search input -->
        <div class="d-flex justify-content-begin">
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="goAdd"
            >
              <feather-icon icon="PlusCircleIcon" /> Add
            </b-button>
          </b-form-group>
        </div>
      </b-col>
      <b-col
        md="6"
        xl="6"
        class="mb-1 text-right"
      >
        <!-- input search -->
        <div class="custom-search justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <span class="mr-1">Search</span>
              <b-form-input
                v-model="searchTerm"
                placeholder="Name, Email, Role, Position"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="hrmsAvatar + props.row.pictureName"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: isActive -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <b-badge variant="light-secondary">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </b-badge>
              </template>
              <b-dropdown-item @click="gotoDetail(props.row.employeeNo)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>View</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.modal-select2
                @click="sendInfo(props.row)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '25', '50', '100']"
              class="mx-1"
              @input="value => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- Modal Edit User -->
    <b-modal
      id="modal-select2"
      title="Edit User"
      ok-title="Save"
      cancel-variant="outline-secondary"
      @ok="submit"
    >
      <b-form>
        <b-form-group
          label="Full Name"
          label-for="name"
        >
          <b-form-input
            id="full-name"
            placeholder="Enter name"
            readonly
            :value="selectedUser.fullName"
          />
        </b-form-group>
        <b-form-group
          label="Username"
          label-for="name"
        >
          <b-form-input
            id="user-name"
            placeholder="Enter name"
            readonly
            :value="selectedUser.userAccount"
          />
          <b-form-input
            id="user-name"
            placeholder="Enter name"
            hidden
            :value="selectedUser.employeeNo"
          />
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="name"
        >
          <b-form-input
            id="email"
            placeholder="Enter name"
            readonly
            :value="selectedUser.email"
          />
        </b-form-group>
        <b-form-group
          label="Position"
          label-for="name"
        >
          <b-form-input
            id="position-name"
            placeholder="Enter name"
            readonly
            :value="selectedUser.positionName"
          />
        </b-form-group>

        <b-form-group
          label="User Role"
          label-for="vue-select"
        >
          <v-select
            id="vue-select"
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionUserGroupId"
            :clearable="false"
            label="text"
          />
        </b-form-group>
        <b-form-group
          label="Status"
          label-for="readOnlyInput"
        >
          <b-form-checkbox
            v-model="isActive"
            value="true"
            class="custom-control-success"
          >
            Active
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>

    <template #code>
      {{ codeColumnSearch }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormCheckbox,
  BForm,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'
import { codeColumnSearch } from './code'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    vSelect,
    BFormCheckbox,
    BForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      hrmsAvatar: this.$hrmsAvatar,
      selectedUser: [],
      isActive: false,
      selected: '',
      optionUserGroupId: [],
      pageLength: 10,
      dir: false,
      codeColumnSearch,
      columns: [
        {
          label: 'Name',
          field: 'fullName',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Role',
          field: 'userGroupName',
        },
        {
          label: 'Position',
          field: 'positionName',
        },
        {
          label: 'IsActive',
          field: 'isActive',
          hidden: true,
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Active: 'light-success',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Inactive: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    useJwt.getUsers().then(res => {
      this.rows = res.data.list
    })
  },
  mounted() {
    useJwt.getUserGroups().then(res => {
      this.optionUserGroupId = res.data.list
    })
  },
  methods: {
    gotoDetail(employeeNo) {
      this.$router.push({ name: 'user-detail', params: { employeeNo } })
    },
    goAdd() {
      this.$router.replace('user-add')
    },
    sendInfo(item) {
      this.selectedUser = item
      this.selected = { value: item.userGroupId, text: item.userGroupName }
      this.isActive = item.isActive
    },
    submit() {
      useJwt
        .updateUser(
          this.selectedUser.employeeNo,
          this.selected.value,
          this.isActive,
        )
        .then(res => {
          if (res.status === 200) {
            useJwt.getUsers().then(resx => {
              this.rows = resx.data.list
            })
          }
        })
    },
  },
}
</script>
